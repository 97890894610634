.Home {
    padding-top: 20px;
}

.Home .bk_header {
    font-family: "Avenir", sans-serif;
    color: #000;
}

.Home .bk_text {
    font-family: "Avenir Next", sans-serif;
    color: #282c34;
}

.Home .bk_link {
    color: #282c34;
    text-decoration:underline;
}

.Home .bk_button {
    font-family: "Avenir Next", sans-serif;
    color: #fff;
}

.Home .main_container {
    min-width: auto;
    width: 90%;
    margin: auto;
    text-align: left;
}

.Home .text_container {
    min-width: auto;
    width: 100%;
    margin: auto;
    text-align: left;
}

.Registration .logo {
    left: 5%;
    top: 10%;
    width: 90%;
    align-content: center;
    text-align: center;
    padding-bottom: 20px;
}