.Account {
    padding-top: 20px;
}

.Account .bk_header {
    font-family: "Avenir", sans-serif;
}

.Account .bk_text {
    font-family: "Avenir Next", sans-serif;
    color: #282c34;
}

.Account .container {
    min-width: auto;
    width: 90%;
    margin: auto;
    text-align: left;
}

.Account .container2 {
    min-width: auto;
    width: 80%;
    margin: auto;
    text-align: left;
}

.Account .logo {
    left: 5%;
    top: 10%;
    width: 90%;
    align-content: center;
    text-align: center;
    padding-bottom: 20px;
}