.NotFound {
    padding-top: 100px;
    text-align: center;
}

.NotFound .bk_header {
    font-family: "Avenir", sans-serif;
}

.NotFound .bk_text {
    font-family: "Avenir Next", sans-serif;
    color: #282c34;
}