.Registration {
    padding-top: 20px;
}

.Registration .bk_header {
    font-family: "Avenir", sans-serif;
}

.Registration .bk_text {
    font-family: "Avenir Next", sans-serif;
    color: #282c34;
}

.Registration .card_container {
    min-width: auto;
    width: 90%;
    margin: auto;
    text-align: left;
}

.Registration .logo {
    left: 5%;
    top: 10%;
    width: 90%;
    align-content: center;
    text-align: center;
    padding-bottom: 20px;
}